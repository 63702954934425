@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/a11y';
@import 'swiper/scss/autoplay';

.swiper {
  width: 100%;
  height: 245px;

  @media #{$medium-up} {
    height: 320px;
  }

  margin-bottom: 10px;

  .swiper-slide {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;

    @media #{$medium-up} {
      border-radius: 10px;
      max-width: calc(100% - 140px);
    }

    @media #{$large-up} {
      max-width: calc(100% - 180px);
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgb(255 255 255 / 75%);
    border-radius: 50%;
    border: solid 0.5px #f1f1f1;
    box-shadow: 0 1px 4px #0000004a;
    display: none;
    height: 30px;
    top: calc(50% + 7.5px);
    width: 30px;

    &::after {
      font-size: 10px;
    }

    @media #{$small-up} {
      display: flex;
      box-shadow: 0 0 4px #0000004a;
      font-size: 24px;
    }
  }

  .swiper-pagination {
    margin-bottom: 10px;
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background: rgb(0 0 0 / 50%);
    border: 0.5px solid #e0e0e0;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    border: 0.5px solid #a4a4a4;
  }
}
