@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'getty/config/z_index';

$fma-row: 4;

.gridFmaContainer {
  background-color: $gi-black;
  height: 330px;
  grid-row-start: $fma-row;
  grid-column-start: 1;
  grid-column-end: 2;
  position: relative;

  -ms-grid-row: $fma-row;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$medium-up} {
    grid-column-end: 3;
    -ms-grid-column-span: 3;
  }

  @media #{$large-up} {
    grid-column-end: 5;
    -ms-grid-column-span: 5;
  }

  @media #{$xxlarge-up} {
    grid-column-end: 6;
    -ms-grid-column-span: 6;
  }
}

.gridItemLinkFma {
  height: inherit;
  color: inherit;

  display: block;
  overflow: hidden;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.gridItemImage {
  position: absolute;
  top: 0;
  left: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform $complex-duration ease;
  margin: 0;
  width: 100.25%;
  height: 100.25%;
}

.fmaDetails {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  padding-left: 1.875rem;
  position: relative;
  top: 70px;
  text-decoration: none;
  z-index: $square-grid-item-details-z-index;
  transition: opacity $complex-duration ease;

  @media #{$large-up} {
    top: 100px;
    padding-left: 4.75rem;
  }
}

.fmaDetailsFullWidth {
  padding-right: 1.875rem;

  @media #{$small-up} {
    margin-right: 240px;
  }
}

.fmaTitle {
  text-decoration: none;
}

.fmaSubTitle {
  display: none;
  text-decoration: none;
  // Note: we don't actually have requirements for exactly how big the
  // subtitle should be or how much space it should take up. These values are
  // just placeholders for example purposes. Once an actual subtitled FMA
  // comes up, change these values accordingly.
  @media #{$small-up} {
    display: block;
    font-size: 20px;
    max-width: 520px;
  }
}

.fmaText {
  padding-top: 8px;
  width: 70%;

  //@media #{$mobile} {
  //  width: 100%;
  //}

  &:hover {
    text-decoration: underline;
  }
}

.fmaIcon {
  color: $gi-white;
  height: 30px;
  width: 135px;
  position: absolute;
  right: 15px;
  bottom: 32px;

  @media #{$small-up} {
    height: 60px;
    width: 150px;
    right: 70px;
    bottom: 36px;
  }
}

.fmaArrowIcon {
  margin-left: 5px;
}

.textLinkSly {
  &:hover {
    text-decoration: none;
  }
}
