@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';

.cardContainer {
  height: 100%;
  margin: 0;
  padding: 20px;

  @media #{$medium-up} {
    padding: 35px 20px;
  }
}

.cardVideo {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  @media #{$medium-up} {
    border-radius: 10px;
  }
}
.cardImage {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @media #{$medium-up} {
    border-radius: 10px;
  }
}

.cardContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

.cardHeaderImage {
  max-height: 40px;
  margin-bottom: 15px;

  @media #{$medium-up} {
    max-height: fit-content;
  }
}

.cardTitle {
  font-size: 21px;
  margin: 0 auto 10px;

  @media #{$small-up} {
    font-size: 24px;
    width: 75%;
  }

  @media #{$medium-up} {
    font-size: 32px;
    width: 80%;
  }

  @media #{$large-up} {
    font-size: 36px;
    width: 60%;
  }
}

.cardTitleImage {
  height: 30px;
  margin-bottom: 15px;
  width: unset;

  @media #{$medium-up} {
    margin-bottom: 20px;
  }
}

.cardSubTitle {
  font-size: 14px;
  margin: 0 40px 10px;

  @media #{$small-up} {
    font-size: 18px;
    max-width: 500px;
  }

  @media #{$medium-up} {
    font-size: 21px;
    margin: 0 150px 15px;
    max-width: 80%;
  }

  @media #{$large-up} {
    max-width: 60%;
  }
}
.cardContentGroup {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: auto;
  width: 100%;
}

.cardCtaLink {
  background-color: $purple;
  border-radius: 4px;
  border: 1px solid $purple-darker;
  bottom: 46px;
  color: white;
  display: inline-block;
  font-size: 10px;
  margin-bottom: 25px;
  min-width: 170px;
  padding: 11px 16px;

  &:hover {
    background-color: $purple-darker;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  @media #{$small-up} {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

/* themes */

.light {
  color: white;
  border-color: white;
}

.dark {
  color: $gi-black;
  border-color: $gi-black;
}
