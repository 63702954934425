@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'getty/config/_z_index';

.container {
  display: grid;
  grid-auto-flow: row dense;
  position: relative;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);

  @media #{$medium-up} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$large-up} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media #{$xxlarge-up} {
    grid-template-columns: repeat(5, 1fr);
  }

  &.columnCountConstant {
    grid-template-columns: repeat(3, 1fr);

    @media #{$medium-up} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media #{$large-up} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media #{$xxlarge-up} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.gridItemContainer {
  &.doubleSize {
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 2;
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  &:hover {
    .gridItemLink:after {
      opacity: 0.3;
    }
    .gridItemImage {
      transform: scale(1.1);
    }
    .gridItemInfo {
      opacity: 1;
    }
  }
}

.gridItemLink {
  display: block;
  overflow: hidden;
  position: relative;

  // Fix specific for Firefox
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:after {
    background-color: $gi-black;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity $complex-duration ease;
  }
}

.gridItemImage {
  position: absolute;
  top: 0;
  left: 0;
  // The grid can't always divide the space into cells that have integer dimensions.
  // This means that the images end up being bordered by a tiny (but noticable
  // and annoying) white line on some browsers. Bumping the image size up by ~1px
  // removes this.
  height: 100.25%;
  width: 100.25%;
  margin: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform $complex-duration ease;
}

.gridItemInfo {
  color: $gi-white;
  bottom: 0;
  line-height: 1.4;
  opacity: 0;
  padding: 32px;
  position: absolute;
  text-decoration: none;
  z-index: 1;
  transition: opacity $complex-duration ease;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

.gridItemId {
  font-size: 0.75em;
}
